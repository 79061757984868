import type { ModuleOptions, RefreshHandler } from '@sidebase/nuxt-auth';

const minuteInMs = 60 * 1000;
const fiveMinutesInMs = 5 * minuteInMs;
const threeMinutesInMs = 3 * minuteInMs;

class CustomRefreshHandler implements RefreshHandler {
  auth?: ReturnType<typeof useAuth>;
  runtimeConfig?: ModuleOptions;
  refetchIntervalTimer?: ReturnType<typeof setInterval>;
  checkTokenIntervalTimer?: ReturnType<typeof setInterval>;

  init(): void {
    this.runtimeConfig = useRuntimeConfig().public.auth;
    this.auth = useAuth();

    const refetchIntervalTime = fiveMinutesInMs;
    this.refetchIntervalTimer = setInterval(() => {
      if (process.env.NODE_ENV === 'development') {
        return;
      }

      if (this.auth?.status.value === 'unauthenticated') {
        return;
      }

      if (this.auth?.data.value) {
        this.auth.refresh();

        return;
      }
    }, refetchIntervalTime);

    const checkTokenIntervalTime = threeMinutesInMs;
    this.checkTokenIntervalTimer = setInterval(() => {
      if (process.env.NODE_ENV === 'development') {
        return;
      }

      const token = localStorage.getItem('_snap_token');
      const cookieToken = document.cookie.includes('auth.token');

      if (this.auth?.status.value === 'authenticated' && !token && !cookieToken) {
        this.auth.signOut({ callbackUrl: '/login' });
      }
    }, checkTokenIntervalTime);
  }

  destroy(): void {
    this.stopRefresh();

    this.auth = undefined;
    this.runtimeConfig = undefined;
  }

  stopRefresh(): void {
    clearInterval(this.refetchIntervalTimer);
    clearInterval(this.checkTokenIntervalTimer);
  }
}

export default new CustomRefreshHandler();
